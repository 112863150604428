/* You can add global styles to this file, and also import other style files */
:root {
  --azul-oscuro: #5851d3;
  --color-azul: #2a93cd;
  --color-azul: #2a93cd;
  --color-azul-opacidad: rgba(42, 147, 205, 0.75);
  --color-azul-2: #2b92d0;
  --color-azul-3: #4db9d5;
  --color-azul-4: #4d80c0;
  --color-azul-5: #4d9ecb;
  --color-azul-6: #2a5c7a;
  --verde-claro: #74d2d4;
  --verde-obscuro: #54ccdd;
  --gris-claro: #f4f4f4;
  --gris: #f8f8f8;
  --gris-dos: #8c8c8c;
  --gris-tres: #f0f0f0;
  --gris-cuatro: #eaeaea;
  --gris-cinco: #dddddd;
  --gris-seis: #ebebeb;
  --gris-siete: #707070;
  --blanco: #fff;
  --negro: #000;
  --rojo: #ff5858;
  --opacidad: rgba(154, 154, 154, 0.6);
  --gradiente-uno: linear-gradient(to right, #5bbdbc, #43bdd9);
  --gradiente-dos: linear-gradient(to bottom, #4e7ec1, #5851d3);
  --gradiente-tres: linear-gradient(to bottom, #5abed8, #4b78d6);
  --gradiente-cuatro: linear-gradient(to bottom, #e2f3f8, #5cbfd8);
  --gradiente-cinco: linear-gradient(to top, #b4cbe4, #5082c1);
  --gradiente-seis: linear-gradient(to bottom, #2b92d0, #4db9d5);
  --gradiente-siete: linear-gradient(to top, #4db9d5, #594dd5);
  --gradiente-ocho: linear-gradient(to bottom, #4d80c0, #594dd5);
}

.fondo {
  background-image: url(/assets/background.png);
  background-color: blue;
  background-repeat: no-repeat;
  background-size: cover;
}

.fondo2 {
  background-image: url(/assets/background3.jpg);
  background-color: blue;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
}

.logo img {
  height: 8rem;
}

img {
  vertical-align: middle;
}

.texto {
  /* color: rgba(0, 0, 0, 0.404); */
  color: #000;
}

button {
  width: 100% !important;
  min-width: 50px !important;
}

.boton {
  margin-top: 1rem;
  border-radius: 50px;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  background: var(--gradiente-tres);
  color: white;
  border: 0;
}

.formulario {
  width: 45%;
}

.form-control {
  background-color: #f5f5f5;
  font-size: 1rem;
  border: 0 !important;
  border-radius: 10px !important;
  height: 5rem !important;
}

.maximo {
  width: 100%;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.derechos {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 0.7rem;
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: #5f5f5f;
}

@media (max-width: 768px) {
  .formulario {
    width: 90%;
  }

  .derechos {
    font-size: 0.5rem;
  }
}

@media (max-width: 900px) {
  .logo img {
    height: 6rem;
  }
}

html,
body {
  background-color: var(--gris-claro);
  /* height: 100%; */
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.texto-azul {
  color: var(--color-azul);
}

.row-fila {
  background: var(--gris-tres);
  border-radius: 35px;
}

/*Contenedores*/
.left {
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
}

.posting-container,
.posting-container2 {
  padding-top: 2rem;
}

.right {
  display: none;
}
.center-formulario {
  background-color: var(--gris);
  padding: 1rem;
  border-radius: 25px;
  box-shadow: -3px 5px 42px -5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -3px 5px 42px -5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -3px 5px 42px -5px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1919px) {
  .contenedor {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
  }

  .left {
    width: 421px;
    max-width: 421px;
    /* margin-left: auto;
    margin-right: auto; */
  }

  .posting-container {
    margin-left: 48px;
    width: 900px;
  }

  .posting-container2 {
    margin-left: 48px;
    width: 1100px;
  }

  .title-one-p {
    font-size: 27px;
  }
}

@media (min-width: 1439px) and (max-width: 1919px) {
  .contenedor {
    display: flex;
    /* justify-content: center; */
  }

  .left {
    width: 400px;
    max-width: 400px;
    min-width: 400px;
  }

  .posting-container {
    margin-left: 48px;
    width: 700px;
  }

  .posting-container2 {
    margin-left: 48px;
    width: 900px;
  }

  .title-one-p {
    font-size: 24px;
  }
}

@media (min-width: 1199px) and (max-width: 1439px) {
  .contenedor {
    display: flex;
    /* justify-content: center; */
  }

  .left {
    width: 275px;
    max-width: 275px;
    min-width: 275px;
  }

  .posting-container {
    margin-left: 48px;
    width: 620px;
  }

  .posting-container2 {
    margin-left: 48px;
    width: 820px;
  }

  .title-one-p {
    font-size: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .contenedor {
    display: flex;
    /* justify-content: center; */
  }

  .left {
    width: 100%;
    max-width: 255px;
    min-width: 238px;
  }

  .posting-container {
    margin-left: 48px;
    width: 600px;
  }

  .posting-container2 {
    margin-left: 48px;
    width: 800px;
  }

  .title-one-p {
    font-size: 24px;
  }
}

/*Loader*/
.carga {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gris);
  opacity: 0.5;
  z-index: 99;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 5px solid;
  border-color: transparent var(--color-azul) var(--color-azul);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/* titulos */
.title-one-p {
  color: var(--gris-siete);
}

.iconos-svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.iconos {
  color: var(--color-azul);
  font-size: 30px;
  width: 30px;
  height: 30px;
}

/* Verificación */
.contenedor-blanco {
  position: sticky;
  top: 112px;
  background-color: white;
  width: 100%;
  padding: 1rem;
  padding-bottom: 2rem;
  border-radius: 25px;
}

.min-height-1 {
  height: 500px;
}

.mid-center-formulario {
  min-height: 200px;
}

.bottom-center-formulario {
  min-height: 200px;
}
